import { ThemeConfig } from './types';

export const h5: ThemeConfig = {
    tenant: 'h5',
    baseColor: '#8ABD24',
    navColor: '#fff',
    textColor: '#444',
    hoverColor: '#8ABD24',
    disabled: '#999999',
    inputBgColor: "#8ABD24",
    matrixHoverColor: '#8ABD24',
    matrixHoverTextColor: '#444',
    matrixOddBgColor: '#8ABD24',
    matrixOddTextColor: '#444'
};

export default h5;